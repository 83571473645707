import { FC, useEffect, useRef, useState } from 'react';
import styles from './message.module.scss';
import useThread from '../../hooks/useThread';
import { ThreadMessageAuthor } from '@/models/thread';
import MessageOptions from '../MessageOptions';
import useBlock from '../../hooks/useBlock';
import SourcesIcon from '@/assets/icons/database.svg?react';
import AnswerIcon from '@/assets/icons/dataflow.svg?react';
import { getRandomNumber } from '@/utils/functions';
import useSession from '@/hooks/useSession';

interface MessageProps {
  content: string;
  author: ThreadMessageAuthor;
  lastMessage?: boolean;
}

const Message: FC<MessageProps> = ({
  author,
  content,
  lastMessage = false,
}) => {
  const [text, setText] = useState<string>('');
  const { pushUserEvent } = useSession();
  const { status, setStatus } = useThread();
  const { block } = useBlock();
  const cursor = useRef<number>(0);
  const { sources } = block;
  const typingInterval = useRef<NodeJS.Timeout | undefined>(undefined);
  const isTypingMessage =
    lastMessage && author === 'ASSISTANT' && status === 'TYPING';
  const isLoadingMessage =
    lastMessage && author === 'ASSISTANT' && status === 'FETCHING';

  useEffect(() => {
    return () => clearInterval(typingInterval.current);
  }, []);

  useEffect(() => {
    if (isTypingMessage && cursor.current < content.length) {
      if (typingInterval.current) {
        clearInterval(typingInterval.current);
      }

      typingInterval.current = setInterval(() => {
        if (cursor.current < content.length) {
          let nextCursor = cursor.current + Math.floor(getRandomNumber(1, 10));
          if (nextCursor > content.length) {
            nextCursor = content.length;
          }
          cursor.current = nextCursor;
          const newText = content.substring(0, cursor.current);
          setText(newText);
        } else {
          clearInterval(typingInterval.current);
        }
      }, 40);
    } else if (!lastMessage || cursor.current === 0) {
      setText(content);
    }
  }, [lastMessage, author, content, status, setStatus, isTypingMessage]);

  useEffect(() => {
    if (!isTypingMessage && cursor.current !== 0) {
      setText(content);
      clearInterval(typingInterval.current);
      cursor.current = 0;
    }
  }, [isTypingMessage, content]);

  return (
    <div
      data-testid={`msg-${author}`}
      className={`${styles.message} ${
        author === 'USER' ? styles['user-message'] : styles['assistant-message']
      }`}
    >
      {author === 'ASSISTANT' && !isLoadingMessage && (
        <strong>
          {' '}
          <AnswerIcon />
          Answer
        </strong>
      )}

      <div
        data-testid={`msg-${author}-content`}
        className={styles['message-content']}
        data-typing={
          lastMessage && author === 'ASSISTANT' && status === 'TYPING'
        }
      >
        {(text || ' ')
          .split('\n')
          .filter((content) => content !== '')
          .map((line, index) => (
            <p className={styles['message-content-paragraph']} key={index}>
              {line}{' '}
            </p>
          ))}
      </div>

      {author === 'ASSISTANT' &&
        sources &&
        sources.length > 0 &&
        (!lastMessage || status === 'READY') && (
          <div className={styles['message-sources']}>
            <strong>
              <SourcesIcon /> Sources ({sources.length})
            </strong>
            <ul>
              {sources?.map(({ url, title }, i) => {
                const baseURL = url.match(/^(https?:\/\/[^/]+)/);
                let truncatedTitle = title;
                if (truncatedTitle.length > 60) {
                  truncatedTitle = title.substring(0, 57) + '...';
                }
                return (
                  <li key={i}>
                    <blockquote cite={url}>
                      <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={() => {
                          pushUserEvent('click_response_source');
                        }}
                      >
                        {truncatedTitle}
                      </a>
                      <footer>
                        <cite>
                          {baseURL && baseURL.length > 0 && `— ${baseURL[0]}`}{' '}
                        </cite>
                      </footer>
                    </blockquote>
                  </li>
                );
              })}
            </ul>
          </div>
        )}

      {author === 'ASSISTANT' && (
        <MessageOptions author={author} content={content} />
      )}
    </div>
  );
};

export default Message;
